import { firestore as db } from "@/lib/hooks/firebase";
import { OKRData, PlanDefinition, PlanInfo } from "@/lib/interfaces";
import logger from '@/lib/logger';

import {
    collection,
    addDoc,
} from "firebase/firestore";

export interface EmailMessage {
    to: string[];
    cc?: string[];
    bcc?: string[];
    message: EmailMessageBody;
}

export interface EmailMessageBody {
    subject: string;
    html?: string;
    text?: string;
    type: EmailMessageType;
    user: EmailMessageUser;
}

export enum EmailMessageType {
    NewUser = "NewUser",
    UserLogin = "UserLogin",
    SessionStart = "SessionStart",
    NewPlan = "NewPlan",
    MilestoneComplete = "MilestoneComplete",
    PlanComplete = "PlanComplete",
    ViewPlan = "ViewPlan",
    ManagePlan = "ManagePlan",
    AppFeedback = "AppFeedback",
    FeedbackNote = "FeedbackNote",
    SubscriptionUpdate = "SubscriptionUpdate",
    SubmitPlanTemplate = "SubmitPlanTemplate",
}

export interface EmailMessageUser {
    id: string;
    email?: string;
}

type SendEmailMessageProps = {
    subject: string;
    type: EmailMessageType;
    html: string;
    user: EmailMessageUser;
    onSuccess?: () => void;
    onError?: () => void;
};

export const sendEmailMessage = async ({
    subject,
    type,
    html,
    user,
    onSuccess,
    onError,
}: SendEmailMessageProps) => {
    const emailMessage: EmailMessage = {
        to: ["plan-quest@googlegroups.com"],
        message: {
            subject,
            html,
            type,
            user
        },
    };

    // Save the emailMessage to Firestore
    const messagesRef = collection(db, "messages");

    try {
        await addDoc(messagesRef, emailMessage);
        onSuccess?.();
    } catch (error) {
        onError?.();
    }
};

export const formatPlanDataAsHtml = (planMeta: PlanInfo, planDefinition: PlanDefinition, user) => {
    try {
        const numberOfMilestones = Array.isArray(planDefinition.events) ? planDefinition.events.length : 0;
        const totalNumberOfKeyResults = Array.isArray(planDefinition.okrs) ? planDefinition.okrs.reduce((total, okr) => total + (Array.isArray(okr.keyResults) ? okr.keyResults.length : 0), 0) : 0;

        let totalDuration = 'Not specified';
        if (Array.isArray(planDefinition.events) && planDefinition.events.length > 0) {
            const startDates = planDefinition.events.map(event => new Date(event.startDate).getTime());
            const endDates = planDefinition.events.map(event => new Date(event.endDate).getTime());
            const startDate = new Date(Math.min(...startDates));
            const endDate = new Date(Math.max(...endDates));
            const durationInMilliseconds = endDate.getTime() - startDate.getTime();
            totalDuration = (durationInMilliseconds / (1000 * 60 * 60 * 24)).toFixed(2); // Convert to days and format as string
        }

        const creationMode = planDefinition.init && typeof planDefinition.init.mode === 'string' ? planDefinition.init.mode : 'Unknown';
        const userPrompt = planDefinition.init?.userPrompt;
        const userName = user && typeof user.displayName === 'string' ? user.displayName : 'Unknown User';
        const userEmail = user && typeof user.email === 'string' ? user.email : 'No email provided';
        const userImage = user && typeof user.photoURL === 'string' ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 10px auto; display: block;">` : '';
        const planTitle = planMeta && typeof planMeta.title === 'string' ? planMeta.title : 'Untitled';
        const planDescription = planMeta && typeof planMeta.description === 'string' ? planMeta.description : 'No description provided';
        const planCreationDate = planMeta && typeof planMeta.creationDate === 'string' ? planMeta.creationDate : 'Unknown date';

        return `
          <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 20px auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; background-color: #f9f9f9;">
              <h1 style="color: #333;">New Plan Created: ${planTitle}</h1>
              ${userImage}
              <h2>${planTitle}</h2>
              <p><strong>Created by:</strong> ${userName}</p>
              <p><strong>Email:</strong> ${userEmail}</p>
              <p><strong>Description:</strong> ${planDescription}</p>
              <p><strong>Creation Date:</strong> ${planCreationDate}</p>
              <p><strong>Number of Milestones:</strong> ${numberOfMilestones}</p>
              <p><strong>Total Number of Key Results:</strong> ${totalNumberOfKeyResults}</p>
              <p><strong>Total Duration of Plan (days):</strong> ${totalDuration}</p>
              <p><strong>Plan Created Via:</strong> ${creationMode}</p>
              ${userPrompt ? `<p><strong>User Prompt:</strong> ${userPrompt}</p>` : ''}
          </div>
      `;
    } catch (error) {
        console.error('Error formatting plan data as HTML:', error);
        return 'Error formatting HTML.';
    }
};

export const formatMilestoneCompletionAsHtml = (planMeta: PlanInfo, okrData: OKRData, user) => {
    try {
        const userName = user && typeof user.displayName === 'string' ? user.displayName : 'Unknown User';
        const userEmail = user && typeof user.email === 'string' ? user.email : 'No email provided';
        const userImage = user && typeof user.photoURL === 'string' ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 10px auto; display: block;">` : '';
        const planTitle = planMeta && typeof planMeta.title === 'string' ? planMeta.title : 'Untitled';

        return `
          <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 20px auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; background-color: #f9f9f9;">
              <h1 style="color: #333;">Milestone Completed within Plan: ${planTitle}</h1>
              ${userImage}
              <h2>Plan: ${planTitle}</h2>
              <h3>Objective: ${okrData.objective}</h3>
              <p><strong>Created by:</strong> ${userName}</p>
              <p><strong>Email:</strong> ${userEmail}</p>
          </div>
      `;
    } catch (error) {
        console.error('Error formatting milestone completion data as HTML:', error);
        return 'Error formatting HTML.';
    }
};

export const formatPlanCompletionAsHtml = (planMeta: PlanInfo, user) => {
    try {
        const userName = user && typeof user.displayName === 'string' ? user.displayName : 'Unknown User';
        const userEmail = user && typeof user.email === 'string' ? user.email : 'No email provided';
        const userImage = user && typeof user.photoURL === 'string' ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 10px auto; display: block;">` : '';
        const planTitle = planMeta && typeof planMeta.title === 'string' ? planMeta.title : 'Untitled';
        const planDescription = planMeta && typeof planMeta.description === 'string' ? planMeta.description : 'No description provided';
        const planCreationDate = planMeta && typeof planMeta.creationDate === 'string' ? planMeta.creationDate : 'Unknown date';

        return `
          <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 20px auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; background-color: #f9f9f9;">
              <h1 style="color: #333;">Plan Completed: ${planTitle}</h1>
              ${userImage}
              <h2>Plan: ${planTitle}</h2>
              <p><strong>Created by:</strong> ${userName}</p>
              <p><strong>Email:</strong> ${userEmail}</p>
              <p><strong>Description:</strong> ${planDescription}</p>
              <p><strong>Creation Date:</strong> ${planCreationDate}</p>
          </div>
      `;
    } catch (error) {
        console.error('Error formatting plan completion data as HTML:', error);
        return 'Error formatting HTML.';
    }
};

export const sendEmailMessageForNewPlan = async (planMeta: PlanInfo, planDefinition: PlanDefinition, user) => {
    const subject = `New Plan Created: ${planMeta.title}`;

    logger.debug('Sending email for new plan:', { planMeta, planDefinition, user });

    const html = formatPlanDataAsHtml(planMeta, planDefinition, user);
    const type = EmailMessageType.NewPlan;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
}

export const sendEmailMessageForMilestoneComplete = async (planMeta: PlanInfo, okrData: OKRData, user) => {
    const subject = `Milestone Completed: ${planMeta.title}`;

    logger.debug('Sending email for milestone complete:', { planMeta, okrData, user });

    const html = formatMilestoneCompletionAsHtml(planMeta, okrData, user);
    const type = EmailMessageType.MilestoneComplete;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
}

export const sendEmailMessageForPlanComplete = async (planMeta: PlanInfo, user) => {
    const subject = `Plan Completed: ${planMeta.title}`;

    logger.debug('Sending email for plan complete:', { planMeta, user });

    const html = formatPlanCompletionAsHtml(planMeta, user);
    const type = EmailMessageType.PlanComplete;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
}

export const sendEmailMessageForNewUser = async (user) => {
    const subject = `New User: ${user.displayName}`;

    logger.debug('Sending email for new user:', { user });

    const html = `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; text-align: center; background-color: #f9f9f9;">
    <h1 style="color: #333;">New User Signed Up</h1>
    ${user?.photoURL ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 0 auto; display: block;">` : ''}
    <div style="text-align: left; margin-top: 20px;">
        <p><strong>Name:</strong> ${user?.displayName || 'Unknown'}</p>
        <p><strong>Email:</strong> ${user?.email}</p>
        <p><strong>User ID:</strong> ${user?.uid}</p>
        <p><strong>Signup Date:</strong> ${new Date().toLocaleDateString()}</p>
    </div>
</div>`;

    const type = EmailMessageType.NewUser;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
}

export const sendEmailMessageForLogin = async (user) => {
    const subject = `User Login: ${user.displayName}`;

    logger.debug('Sending email for user login:', { user });

    const html = `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; text-align: center; background-color: #f9f9f9;">
    <h1 style="color: #333;">User Login</h1>
    ${user?.photoURL ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 0 auto; display: block;">` : ''}
    <div style="text-align: left; margin-top: 20px;">
        <p><strong>Name:</strong> ${user?.displayName || 'Unknown'}</p>
        <p><strong>Email:</strong> ${user?.email}</p>
        <p><strong>User ID:</strong> ${user?.uid}</p>
        <p><strong>Login Date:</strong> ${new Date().toLocaleDateString()}</p>
    </div>
</div>`;

    const type = EmailMessageType.UserLogin;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
}

export const sendEmailMessageForSessionStart = async (user, sessionCount) => {
    const subject = `User Session Start (${sessionCount}): ${user.displayName}`;

    logger.debug('Sending email for user session start:', { user });

    const html = `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; text-align: center; background-color: #f9f9f9;">
<h1 style="color: #333;">User Session Start</h1>
${user?.photoURL ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 0 auto; display: block;">` : ''}
<div style="text-align: left; margin-top: 20px;">
    <p><strong>Name:</strong> ${user?.displayName || 'Unknown'}</p>
    <p><strong>Email:</strong> ${user?.email}</p>
    <p><strong>User ID:</strong> ${user?.uid}</p>
    <p><strong>Session Start Date:</strong> ${new Date().toLocaleDateString()}</p>
</div>
</div>`;

    const type = EmailMessageType.SessionStart;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
}

export const sendEmailMessageForViewPlan = async (user, source) => {
    const subject = `Viewed Subscriptions Plans: ${user.displayName || 'Unknown'}`;

    logger.debug('Sending email for view plan:', { user, source });

    const html = `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; text-align: center; background-color: #f9f9f9;">
    <h1 style="color: #333;">User Viewed Plan</h1>
    ${user?.photoURL ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 0 auto; display: block;">` : ''}
    <div style="text-align: left; margin-top: 20px;">
        <p><strong>Source:</strong> ${source}</p>
        <p><strong>Name:</strong> ${user?.displayName || 'Unknown'}</p>
        <p><strong>Email:</strong> ${user?.email}</p>
        <p><strong>User ID:</strong> ${user?.uid}</p>
        <p><strong>View Date:</strong> ${new Date().toLocaleDateString()}</p>
    </div>
    `;

    const type = EmailMessageType.ViewPlan;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
}

export const sendEmailMessageForManagePlan = async (user) => {
    const subject = `Manage Subscriptions Plans: ${user.displayName || 'Unknown'}`;

    logger.debug('Sending email for manage plan:', { user });

    const html = `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; text-align: center; background-color: #f9f9f9;">
    <h1 style="color: #333;">User Managed Plan</h1>
    ${user?.photoURL ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 0 auto; display: block;">` : ''}
    <div style="text-align: left; margin-top: 20px;">
        <p><strong>Name:</strong> ${user?.displayName || 'Unknown'}</p>
        <p><strong>Email:</strong> ${user?.email}</p>
        <p><strong>User ID:</strong> ${user?.uid}</p>
        <p><strong>Manage Date:</strong> ${new Date().toLocaleDateString()}</p>
    </div>`;

    const type = EmailMessageType.ManagePlan;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
};

export const sendEmailMessageForSubscriptionChange = async (firebaseId, stripeId, planType) => {
    const subject = `Subscription Plan Update`;

    logger.debug('Sending email for subscription update:', { firebaseId, stripeId, planType });

    const html = `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; text-align: center; background-color: #f9f9f9;">
            <h1 style="color: #333;">Subscription Plan Updated</h1>
            <div style="text-align: left; margin-top: 20px;">

                <p><strong>Firebase Id:</strong> ${firebaseId}</p>
                <p><strong>Stripe I</strong> ${stripeId}</p>
                <p><strong>Current Plan:</strong> ${planType}</p>
                <p><strong>Update Date:</strong> ${new Date().toLocaleDateString()}</p>
                <p><a href="https://dashboard.stripe.com/customers/${stripeId}" target="_blank" style="color: blue;">View in Stripe Dashboard</a></p>
            </div>
        </div>`;

    const userData = { id: firebaseId };
    await sendEmailMessage({ subject, html, type: EmailMessageType.SubscriptionUpdate, user: userData });
}

export const sendEmailMessageForTemplateSubmission = async (user, planTemplate) => {
    const subject = `Plan Template Sbumitted: ${planTemplate.title}`;

    logger.debug('Sending email for template submission:', { user, planTemplate });

    // Constructing OKRs section with objectives as headers and key results as bullets
    const okrsHtml = planTemplate.okrs.map((okr, index) => {
        const eventDescription = planTemplate.events[index]?.description || 'No event description';

        return `
            <div>
                <h3>${okr.objective}</h3>
                <h4>${eventDescription}</h4>
                <ul>
                    ${okr.keyResults.map(kr => `<li>${kr.description} (Progress: ${kr.progress}%)</li>`).join('')}
                </ul>
            </div>
        `;
    }).join('');

    // Constructing Retrospectives section as bullets
    const retrosHtml = planTemplate.retros.map(retro => `<li>${retro.title}</li>`).join('');

    const html = `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; text-align: center; background-color: #f9f9f9;">
            <h1 style="color: #333;">Plan Template Saved Successfully</h1>
            
            ${user?.photoURL ? `<img src="${user.photoURL}" alt="User Image" style="width: 100px; height: 100px; border-radius: 50%; margin: 0 auto; display: block;">` : ''}
    
            <div style="text-align: left; margin-top: 20px;">

                <p><strong>Plan Title:</strong> ${planTemplate.title}</p>
                <p><strong>Plan Description:</strong> ${planTemplate.description}</p>
                <p><strong>Duration in Days:</strong> ${planTemplate.durationInDays}</p>

                <h2>OKRs</h2>
                ${okrsHtml}
                
                <h2>Retrospectives</h2>
                <ul>
                    ${retrosHtml}
                </ul>

                <p><strong>User Email:</strong> ${user.email}</p>
                <p><strong>User ID:</strong> ${user.uid}</p>
                <p><strong>Save Date:</strong> ${new Date().toLocaleDateString()}</p>
            </div>
        </div>`;


    const type = EmailMessageType.SubmitPlanTemplate;
    const userData = { id: user?.uid, email: user?.email };

    await sendEmailMessage({ subject, html, type, user: userData });
}