
enum LogLevel {
    Debug = 1,
    Info,
    Warn,
    Error,
    Fatal
}

// Assume this comes from an environment variable or configuration file
const currentLogLevel: LogLevel = LogLevel[process.env.NEXT_PUBLIC_LOG_LEVEL as keyof typeof LogLevel] || LogLevel.Error;

const logger = {
    debug: (message: unknown, ...optionalParams: unknown[]) => {
        if (currentLogLevel <= LogLevel.Debug) {
            console.log(`DEBUG: ${message}`, ...optionalParams);
        }
    },

    info: (message: unknown, ...optionalParams: unknown[]) => {
        if (currentLogLevel <= LogLevel.Info) {
            console.log(message, ...optionalParams);
        }
    },

    warn: (message: unknown, ...optionalParams: unknown[]) => {
        if (currentLogLevel <= LogLevel.Warn) {
            console.warn(message, ...optionalParams);
        }
    },

    error: (message: unknown, ...optionalParams: unknown[]) => {
        if (currentLogLevel <= LogLevel.Error) {
            console.error(message, ...optionalParams);
        }
    },

    fatal: (message: unknown, ...optionalParams: unknown[]) => {
        if (currentLogLevel <= LogLevel.Fatal) {
            console.error(`FATAL: ${message}`, ...optionalParams);
            // Here you could add additional logic to handle fatal errors, like alerting or gracefully shutting down the application.
        }
    }
};

export default logger;
